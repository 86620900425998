<template>
  <div
    v-if="itemList.length"
    class="step-diagram"
    :class="stepDiagramClasslist"
  >
    <div v-for="item in itemList" :key="item.id" class="step-diagram__item col">
      <div v-if="item.name" class="step-diagram__item-title">
        {{ item.name }}
      </div>

      <div class="step-diagram__item-bottom row h-justify v-end">
        <div class="step-diagram__item-icon">
          <UiImg :image="item.logo" :width="24" decorative class="img-rwd" />

          <LogoSmall v-if="!item.logo?.length" class="img-rwd" />
        </div>
        <div class="step-diagram__item-number" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import type { UiCeProductBoxesProps } from '~/types'
import { UiImg } from '~ui/components'

const props = defineProps<UiCeProductBoxesProps>()

const stepDiagramClasslist = computed(() => {
  return ['pl', 'de'].includes(props.lang) ? 'step-diagram--text-small' : ''
})
</script>

<style lang="scss">
.step-diagram {
  $step-diagram-grid-gap: rem(26px);

  display: flex;
  flex-wrap: wrap;
  counter-reset: step-diagram-item-number;
  margin-inline: rem(-10px);
  background-color: color(alabaster);
  padding: rem(20px);

  @include media-query(max xxxs) {
    padding: 0;
  }

  @include media-query(xxs) {
    display: grid;
    grid-template-columns: repeat(3, minmax(rem(150px), 1fr));
    gap: $step-diagram-grid-gap;
    margin-left: auto;
    margin-right: auto;
    max-width: rem(800px);
  }

  $base: &;

  &__item {
    background-color: color(white);
    border-radius: em(2px);
    box-shadow:
      0px 8px 16px hsl(0 0% 0% / 5%),
      0px 2px 20px hsl(0 0% 0% / 4%);
    padding: rem(9px);
    min-height: rem(150px);
    min-width: rem(150px);
    flex: 1;
    margin: rem(10px);
    counter-increment: step-diagram-item-number;
    position: relative;

    @include media-query(xxs) {
      width: auto;
      margin: 0;

      &::before,
      &::after {
        content: '';
        display: block;
        border: 2px dashed rgba(color(primary), 50%);
        position: absolute;
      }

      &::before {
        width: $step-diagram-grid-gap;
        height: 0;
        top: 50%;
        left: 100%;
        transform: translateY(-1px);
        border-bottom: none;
        border-left: none;
        border-right: none;
      }

      &::after {
        height: $step-diagram-grid-gap;
        width: 0;
        top: 100%;
        left: 50%;
        transform: translateX(-1px);
        border-bottom: none;
        border-top: none;
        border-right: none;
      }

      &:nth-child(3n)::before,
      &:nth-child(3n + 1):nth-last-child(-n + 3)::after,
      &:nth-child(3n + 1):nth-last-child(-n + 3) ~ &::after {
        display: none;
      }
    }
  }

  &__item-title {
    width: 100%;
    font-size: rem(18px);
    font-weight: 700;
    line-height: 1.15;
    padding-block: em(8px);

    #{$base}--text-small & {
      font-size: rem(16px);
    }
  }

  &__item-bottom {
    margin-top: auto;
    width: 100%;
  }

  &__item-icon {
    height: rem(25px);
    width: 40%;
    overflow: hidden;
  }

  &__item-number::before {
    content: counter(step-diagram-item-number, decimal-leading-zero);
    color: color(white);
    font-size: rem(50px);
    font-weight: 700;
    line-height: 1;
    text-shadow:
      -1px -1px 0 rgba(color(primary), 20%),
      1px -1px 0 rgba(color(primary), 20%),
      -1px 1px 0 rgba(color(primary), 20%),
      1px 1px 0 rgba(color(primary), 20%);
  }
}
</style>
